/* Container Styles */
.prototype-menu-container {
    width: auto;
    min-width: 320px;
    background-color: #363B54;
    padding: 24px;
    border-radius: 12px;
    color: white;
    max-height: 400px;
    display: flex;
    flex-direction: column;
}

/* Header Styles */
.prototype-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    flex-shrink: 0;
}

.prototype-menu-title {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: white;
    min-width: 30px;
    margin-right: 12px;
}

/* Button Styles */
.prototype-menu-add-button {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 8px;
    color: white;
    padding: 8px 16px;
    cursor: pointer;
}

/* Dropdown Styles */
.prototype-menu-dropdown-trigger {
    width: 200px;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    outline: none;
    height: 30px;
    border-radius: 10px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: white;
    margin-bottom: 2px;
}

.prototype-menu-dropdown-content {
    background-color: rgba(71, 102, 140, 0.5);
    backdrop-filter: blur(10px);
    width: 160px;
    border-radius: 10px;
    margin-top: 5px;
    padding: 5px;
}

.prototype-menu-dropdown-item {
    display: flex;
    width: auto;
    height: 24px;
    margin: 5px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    align-items: center;
    color: white;
    border-radius: 5px;
    padding: 0px 5px;
}

.prototype-menu-dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Accordion Styles */
.prototype-menu-accordion-section {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    margin-bottom: 8px;
    box-sizing: border-box;
    padding: 16px;
    
}

.prototype-menu-accordion-header {
    cursor: pointer;
}

.prototype-menu-header-content {
    display: flex;
    align-items: center;
    width: 100%;
}

/* Input Styles */
.prototype-menu-interaction-input {
    width: 160px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    color: white;
    padding: 8px 12px;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    margin-right: auto;
}

.prototype-menu-interaction-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.prototype-menu-interaction-input:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.4);
}

/* Arrow Indicator */
.prototype-menu-arrow-indicator {
    color: white;
    margin-left: auto;
    width: 16px;
    height: 16px;
    opacity: 0.7;
}

/* Scrollable Container */
.prototype-menu-interactions-container {
    overflow-y: auto;
    flex-grow: 1;
    padding-right: 8px;
    gap: 8px;
    display: flex;
    flex-direction: column;
}

/* Scrollbar Styles */
.prototype-menu-interactions-container::-webkit-scrollbar {
    width: 4px;
}

.prototype-menu-interactions-container::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 2px;
}

.prototype-menu-interactions-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
}

.triggerContainer {
    margin-top: 20px;
}
