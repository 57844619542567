.contextmenucontainer{
    display: flex;
    position: relative;
    background: rgba(25, 23, 30, 0.8);
    backdrop-filter: blur(10px);
    right: 0;
    height: 25px;
    border-radius: 5px;
    z-index: 10000;
    color: white;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
}

.contextmenudimscontainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-right: #373A5C 1px solid;
    padding-left: 5px;
}

.contextmenudims{
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 65px;
    justify-content: center;
}

/* .contextmenudims:nth-child(2){
    margin-right: 5px;
}

.contextmenudims:nth-child(3), .contextmenudims:nth-child(4){
    max-width: 55px;
    margin-right: 0px;
}

.contextmenudims:nth-child(3){
    margin-right: 6px;
} */

.contextmenudims p:nth-child(1){
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    margin-right: 2px;
}

.contextmenudims p:nth-child(2){
    color: #fff;
    font-size: 11px;
}

.contextmenubutton{
    display: flex;
    background-color: #30303D;
    width: 22px;
    height: 22px;
    margin: 0px 2px;
    color: #fff;
    align-items: center;
    justify-content: center;
}

.contextmenubuttonactive{
    display: flex;
    background-color: #9CE093; 
    color: #57A74D;
    width: 22px;
    height: 22px;
    margin: 0px 2px;
    align-items: center;
    justify-content: center;
}

.contextmenubuttonprototypingactive{
    background-color: #8DB3E5;
    color: #fff;
}

.contextmenubutton:hover{
    background-color: #303459;
}
.contextmenubutton svg, .contextmenubuttonactive svg{
    width: 12px;
}
