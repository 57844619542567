

.Invite-Member-Password-Email{
    display: flex;
    background-color: #616599;
    border-radius: 100px;
    width: auto;
    height: 34px;
    color: #2F3789;
    text-align: center;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    font-weight: 500;
}

.Invite-Member-Password-header-container{
    display: flex;
    justify-content: space-between;
    padding: 0px;
}