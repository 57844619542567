@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.dashboard{
    background-color: #212226;
    backdrop-filter: blur(10px);
    width: 100%;
    height: 100vh;
    color: white;
}

.dashboard-header-container{
    display: flex;
    padding: 10px 20px 0px 20px;
    flex-direction: row;
    justify-content: space-between;

}

.dashboard-header-group{
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;

}

.dashboard-settings-group{
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
}

.dashboard-header{
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 20px;
}

.dashboard-team{
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: rgba(65, 68, 96, 0.2);
    width: 200px;
    height: 40px;
    border-radius: 10px;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
}

.dashboard-team .dashboard-team-title{
    color: #777FBD;
}

.dashboard-header span{
    font-size: 12px;
    color: #D138E5;
    font-weight: 600;
}

.dashboard-profile{
    display: flex;
    background-color: #292B42;
    width: 44px;
    height: 44px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
}
.dashboard-profile:hover{
    background-color: #25252E;
}

.dashboard-dropdown-menu-content{
    background-color: rgba(25, 23, 30, 0.8);
    backdrop-filter: blur(10px);
    min-width: 150px;
    border-radius: 6px;
    padding: 10px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    margin: 5px;
}

.dashboard-dropdown-menu-content[data-side='top']{
  animation-name: slideDownAndFade;
}
.dashboard-dropdown-menu-content[data-side='right']{
  animation-name: slideLeftAndFade;
}
.dashboard-dropdown-menu-content{
  animation-name: slideUpAndFade;
}
.dashboard-dropdown-menu-content{
  animation-name: slideRightAndFade;
}

.dashboard-dropdown-menu-item{
    display: flex;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    border-radius: 3px;
    align-items: center;
    height: 40px;
    user-select: none;
    outline: none;
    gap: 10px;
    justify-content: flex-start;
    padding-left: 10px;
}

.dashboard-dropdown-menu-item[data-highlighted]{
    background-color: #292B42;
}

.file-component{
    background-color: rgba(25, 23, 30, 0.9);
    border-radius: 10px;
    padding: 10px;
}

.file-component:hover{
    background-color: rgba(25, 23, 30, 0.7);
}

.file-component a{
    text-decoration: none;
}

.file-component-img{
    display: flex;
    height: 135px;
    width: 100%;
    background-color: #30303D;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.file-component-img img{
    height: 50%;
}

.file-component-title{
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    text-decoration-line: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.file-component-subtitle{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    text-decoration-line: none;
}

.dashboard-ScrollAreaRoot {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.dashboard-ScrollAreaViewport {
	width: 100%;
	height: 100%;
	border-radius: inherit;
}

.dashboard-grid{
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    margin: 0px 80px 0px 80px;
    padding-bottom: 200px;
    padding-top: 50px; 
}
