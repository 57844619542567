@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.login-main{
    background-color: #212226;
    height: 100vh;
    font-family: "Inter", sans-serif;
}

.login-header-container{
    padding: 20px;
}

.login-header{
    color: #fff;
    font-family: "Inter", sans-serif;
    font-size: 20px;
}

.login-header span{
    font-size: 12px;
    color: #D138E5;
    font-weight: 600;
}

.login-box{
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #212226;
    background: 
    linear-gradient(#212226 0 0) padding-box, /*this is your grey background*/
    linear-gradient(to bottom, #282A3F 0%, #414356 100%) border-box,
    linear-gradient(to bottom, #414356 0%, #282A3F 100%) border-box;
    color: #313149;
    width: 380px;
    padding: 40px;
    border: 1px solid transparent;
    border-radius: 15px;
    -webkit-box-shadow: 4px 0px 60px 16px rgba(38,39,55,0.5);
    -moz-box-shadow: 4px 0px 60px 16px rgba(38,39,55,0.5);
    box-shadow: 4px 0px 60px 16px rgba(38,39,55,0.5);
    top:  50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.login-box-header{
    color: #fff;
    text-align: center;
    margin-bottom: 100px;
    font-size: 20px;
}

.login-box-input{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.login-box-input label{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.login-box-input input{
    height: 35px;
    border-radius: 10px;
    box-sizing: unset;
    border: none;
    padding: 0px 10px;
}

.login-box-button{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    background-color: #292B42;
    border: none;
    margin-bottom: 20px;
}

.login-box-button:hover{
    background-color: #30303D;
}

.login-box-or{
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 300;
}

.login-box-button span{
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}

.login-box-create-account-container{
    text-align: center;
}

.login-box-create-account{
    color: #fff;
    font-weight: 500;
    text-decoration: none;
}

.login-box-create-account:hover{
    color: #616599;
}