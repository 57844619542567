:root {
    --player-header-width: 350px;
}

.player-container{
    display: flex;
    position: fixed;
    width: 100%; 
    height: auto;
    z-index: 10000;
    margin: 0;
    color: white;
    /* padding: 20px 20px 0px 20px; */
    justify-content: space-between;
}

.player-header{
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: row;
    background: rgba(70, 74, 118, 0.9);
    backdrop-filter: blur(40px);
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 44px;
    width: var(--player-header-width);
    border-radius: 10px;
    align-items: center;
    gap: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.player-logo span{
    font-size: 12px;
    color: #D138E5;
    font-weight: 800;
}

.player-header div p, .player-header div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.player-logo{
    color: #fff;
    font-family: "Inter", sans-serif;
    font-family: 36px;
    background-color: transparent;
    border: none;
}