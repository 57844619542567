.TeamSettingsModalOverlay {
	background-color: rgba(23, 23, 23, 0.9);
	position: fixed;
	inset: 0;
	animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.TeamSettingsModalContent {
    display: flex;
	background: rgba(70, 74, 118, 0.5);
    backdrop-filter: blur(10px);
	border-radius: 10px;
	box-shadow:
		hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
    height: 100%;
	max-width: 730px;
	max-height: 480px;
	padding: 30px;
	animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    flex-direction: column;
	row-gap: 50px;
}