/* Base container */
.code-editor-container {
    width: 100%;
    background: transparent;
    color: #fff;
    font-size: 12px;
}

/* Header styles */
.code-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.panel-title {
    font-size: 12px;
    font-weight: 500;
}

.copy-button {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    background: #292B42;
    border-radius: 4px;
    border: none;
    color: white;
    font-size: 11px;
    cursor: pointer;
}

.copy-button:hover {
    background: #30303D;
}

/* Section styles */
.properties-section {
    padding: 8px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.section-header {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    font-size: 12px;
    padding: 4px 0;
}

.section-content {
    padding: 8px 0 8px 16px;
}

/* Property group styles */
.property-group {
    margin-bottom: 16px;
}

.group-title {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    margin-bottom: 8px;
}

.property-item {
    margin-bottom: 8px;
}

.property-label {
    display: block;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 4px;
}

/* Property input styles */
.property-input-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.input-wrapper {
    flex: 1;
}

.property-input {
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 4px 8px;
    color: white;
    font-size: 11px;
}

.property-input:focus {
    border-color: rgba(59, 130, 246, 0.5);
    outline: none;
}

.color-input {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    background: transparent;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Code preview section */
.code-section {
    padding: 8px 0;
}

.code-preview {
    margin-top: 8px;
    background: rgba(0, 0, 0, 0.2);
    padding: 12px;
    border-radius: 4px;
    font-size: 11px;
    overflow: auto;
    max-height: 300px;
}

.code-preview pre {
    margin: 0;
    white-space: pre-wrap;
}

.code-preview code {
    font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
}

/* Resizable panel styles */
.resizable-panel {
    width: 100%;
    position: relative;
    transition: width 0.3s ease-in-out;
}

.resizable-panel.expanded {
    position: fixed;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 600px;
    background: rgba(70, 74, 118, 0.95);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.expand-button {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 50;
    padding: 4px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    border: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expand-button:hover {
    background: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 0.8);
}

/* Syntax highlighting overrides */
.language-jsx {
    background: transparent !important;
}

/* Scrollbar styles */
.code-preview::-webkit-scrollbar,
.properties-section::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.code-preview::-webkit-scrollbar-track,
.properties-section::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.code-preview::-webkit-scrollbar-thumb,
.properties-section::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

.code-preview::-webkit-scrollbar-thumb:hover,
.properties-section::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

/* Animation */
.expand-button,
.copy-button {
    transition: all 0.2s ease-in-out;
}

.code-editor {
    position: relative;
  }
  
  .code-preview {
    margin-top: 8px;
    background: rgba(0, 0, 0, 0.2);
    padding: 12px;
    border-radius: 4px;
    font-size: 11px;
    overflow: auto;
    max-height: 500px;
    outline: none;
  }
  
  .code-preview:focus {
    background: rgba(0, 0, 0, 0.3);
  }
  
  .error-message {
    color: #ff4d4d;
    font-size: 11px;
    margin-top: 8px;
    padding: 4px 8px;
    background: rgba(255, 77, 77, 0.1);
    border-radius: 4px;
  }

  .editable {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    padding: 0 4px;
    margin: 0 2px;
    outline: none;
    cursor: text;
    display: inline-block;
  }
  
  .editable:hover {
    background: rgba(255, 255, 255, 0.15);
  }
  
  .editable:focus {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.3);
  }
  
  .code-preview {
    color: #d4d4d4;
  }
  
  .code-preview .property {
    color: #9cdcfe;
  }
  
  .code-preview .value {
    color: #ce9178;
  }

  .code-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .code-tabs {
    display: flex;
    gap: 8px;
  }
  
  .tab-button {
    background: transparent;
    border: none;
    color: #fff;
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
  }

  .code-tab-language {
    font-size: 10px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: #fff;
  }

  .code-preview {
    margin-top: 8px;
    background: rgba(0, 0, 0, 0.2);
    padding: 12px;
    border-radius: 4px;
    font-size: 11px;
    font-family: 'Menlo', monospace;
    line-height: 1.5;
  }
  
  .code-line {
    padding: 2px 0;
    margin-left: 16px;
  }
  
  .property {
    color: #9cdcfe;
  }
  
  .non-editable {
    color: #d4d4d4;
    user-select: text;
  }
  
  .editable-code {
    margin-left: 0;
  }

  .code-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    z-index: 100;  /* Ensure header is above other elements */
}

.code-tabs {
    display: flex;
    gap: 8px;
}

.expand-button {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 50;  /* Lower than header */
    padding: 4px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.2);
    border: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
