.choose-ds-container{
    height: auto;
    padding: 10px;
    width: 118px;
    height: 100px;
    border-radius: 10px;
    background-color: #292B42;
    backdrop-filter: blur(40px);
    overflow-y: auto;
}

.choose-ds-trigger{
    background-color: #292B42;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
}

.choose-ds-item{
    color: #fff;
    border-bottom: #4B4C62 solid 1px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
    padding: 10px 10px 10px 5px;
}

.choose-ds-item:hover{
    background-color: #4B4C62;
    border-radius: 5px;
}