
.invite-member-button{
    display: flex;
    background-color: #292B42;
    width: 200px;
    height: 44px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}

.invite-member-button:hover{
    background-color: #25252E;
}

.invite-members-dropdown-menu-content{
    background-color: rgba(55, 57, 75, 0.4);
    backdrop-filter: blur(10px);
    width: 315px;
    border-radius: 6px;
    padding: 10px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    margin: 5px;
}

.invite-members-dropdown-menu-content[data-side='top']{
    animation-name: slideDownAndFade;
}

.invite-members-menu-content[data-side='right']{
    animation-name: slideLeftAndFade;
}

.invite-members-dropdown-menu-content{
    animation-name: slideUpAndFade;
}

.invite-members-dropdown-menu-content{
    animation-name: slideRightAndFade;
}

.invite-members-dropdown-menu-item{
    display: flex;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    border-radius: 3px;
    align-items: center;
    height: 40px;
    user-select: none;
    outline: none;
    gap: 10px;
    justify-content: flex-start;
    padding-left: 10px;
}

.invite-members-dropdown-menu-item[data-highlighted]{
    background-color: #777FBD;
}

.invite-members-dropdown-menu-item{
    display: flex;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    border-radius: 3px;
    align-items: center;
    height: 40px;
    user-select: none;
    outline: none;
    gap: 10px;
    justify-content: flex-start;
    padding-left: 10px;
}

.invite-members-dropdown-menu-item[data-highlighted]{
    background-color: #777FBD;
}


.invite-members-dropdown-header{
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    border-bottom: #4B4C62 solid 1px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.invite-members-dropdown-input{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.invite-members-dropdown-input input{
    height: 34px;
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: rgba(255, 255, 255, 0.15);
    color: #fff;
    margin-bottom: 15px;
}

.invite-members-dropdown-input input::placeholder{
    color: #8A8DA0;
}

.invite-members-dropdown-input button{
    background-color: rgba(81, 90, 177, 0.4);
    border: none;
    outline: none;
    border-radius: 5px;
    height: 30px;
    color: #fff;
}

.invite-member-email-chips-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.invite-member-email-chips{
    display: flex;
    width: auto;
    font-size: 12px;
    height: 24px;
    background-color: rgba(87, 151, 234, 0.4);
    border-radius: 10px;
    color: #fff;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    margin: 5px;
}

.invite-member-email-chips p {
    margin-right: 5px;
}

.invite-member-email-chips svg {
    color: #5797EA;
    opacity: 0;
}

.invite-member-email-chips:hover svg{
    opacity: 100;
}
