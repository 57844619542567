.expandedCodeViewOverlay {
	background-color: rgba(0, 0, 0, 0.6);
	position: fixed;
	inset: 0;
	animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.expandedCodeViewContent {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 20px;
	background: #191820;
    backdrop-filter: blur(10px);
	border-radius: 10px;
	box-shadow:
		hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
    height: 100%;
	max-width: 830px;
	max-height: 580px;
	padding: 20px;
	animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
	row-gap: 50px;
}

.expandedCodeViewEditorPanel,
.expandedCodeViewPreviewPanel {
    flex: 1;
    min-width: 0;
    height: 100%;
    overflow: hidden;
    border-radius: 0px 0px 10px 10px;
}

.expandedCodeViewEditorPanel {
    flex: 0.6; /* 60% width */
    min-width: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.expandedCodeViewEditorPanelContent {
    flex: 1;
    overflow: auto;
    height: 100%;
    min-height: 100%;
    background-color: rgb(41, 45, 62);
}

.expandedCodeViewEditorPanelContent > div {
    height: 100%;
}

.expandedCodeViewPreviewPanel .expandedCodeViewPreviewPanelContent{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #212226;
    border-radius: 0px 0px 10px 10px;
    height: 100%;
    overflow: hidden;
}

.expandedCodeViewPreviewPanelHeader{
    background-color: #1C1C20;
    color: #fff;
    padding: 10px;
    font-size: 14px;
    border-radius: 10px 10px 0px 0px;
}

.expandedCodeViewEditorPanelHeader{
    background-color: #212430;
    color: #fff;
    padding: 10px;
    font-size: 14px;
    border-radius: 10px 10px 0px 0px;
}

.expandedCodeViewPreviewPanel {
    flex: 0.4; /* 40% width */
    min-width: 0;
}