.cpcontainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 35px;
    z-index: 100;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0;
    margin-top: 10px;
}

.cp-button-rounded{
    border-radius: 5px;
}

.cp-button-end-left{
    border-radius: 5px 0px 0px 5px;
}

.cp-button-end-right{
    border-radius: 0px 5px 5px 0px;
}

.cp-button{
    background: rgba(25, 23, 30, 0.9);
    backdrop-filter: blur(40px);
    width:50px;
}

.cp-button:hover{
    background: #30303D;
}

.ElementsButton, .popoverTrigger{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: none;
    background: none;
    width: 100%;
    height: 100%;
    stroke: none;
    stroke-width: 0%;
    outline: none;
    border-style: none;
}

.elementsContainer{
    width: 500px;
    height: 500px;
    background: rgba(25, 23, 30, 0.9);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px;
    overflow: hidden;
    overflow-y: auto;
}

.elementsSection{
    margin-bottom: 40px;
}

.elementsSection-ds{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid #4B4C62 1px;
}

.elementsSection-ds-option-buttons{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.elementsSection-ds h4{
    border: none !important;
}

.elementsSection h4{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    border-bottom: solid #4B4C62 1px;
    padding-bottom: 5px;
}

.elementsSectionContent{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.elementsSectionContent .element-preview-container{
    display: flex;
    width: 90px;
    height: 90px;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    align-content: center;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
}

.elementsSectionContent .element-preview-container:hover{
    background-color: rgba(34, 36, 54, 0.6);
}

.elementsSectionContent .element-preview-container img{
    display: block;
    width: 100%;
    align-self: center;
}

.elementsSectionContent .element-preview-container h3{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #777FBD;
    padding-top: 20px;
}

.elementsSectionText img{
    max-width: 75px;
}

.elementsSectionImage img{
    max-width: 70px;
}

.elementsSectionTextfield img{
    max-width: 75px;
}

.elementsSectionButton img{
    max-width: 75px;
}

.elementsSectionSwitch img{
    max-width: 55px;
}

.elementsSectionContainer{
    display: flex;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.elementsSectionContainer img{
    max-width: 40px;
}

.preview-container {
    width: 150px;
    min-width: 0;
    overflow: hidden;
}

.element-panel-content > pre {
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    word-break: break-all !important;
    overflow-wrap: break-word !important;
    width: 100%;
    margin: 8px 0;
    padding: 12px;
    box-sizing: border-box;
    overflow: hidden;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
    font-size: 13px;
}

