.layer-title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #292B42;
    margin-top: 10px;
}

.layer-title{
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: #fff;
    margin-top: 5px;
}

.lspcontainer {
    position: absolute;
    left: 0;
    margin-right: 0px;
    z-index: 100;
    color: white; 
    width: 280px;
    padding: 10px;
    box-sizing: border-box;
    padding-bottom: 100px;
    height: 100%;
  }

  .lspscroll{
    padding-top: 45px;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    scroll-behavior: smooth !important;
    -webkit-overflow-scrolling: touch; 
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 200px;
  }

  .lspcontainer .layers, 
  .lspcontainer .code-panel-wrapper {
    padding: 10px;
    background: rgba(25, 23, 30, 0.9);
    backdrop-filter: blur(40px);
    margin-bottom: 10px;
    border-radius: 10px;
  }
  
  .layer-scroll-area {
    width: 100%;
    max-height: 200px;
    overflow: auto;
    margin-top: 10px;
  }
