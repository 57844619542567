
.NewFileModalOverlay {
	background-color: rgba(23, 23, 23, 0.7);
	position: fixed;
	inset: 0;
	animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.NewFileModalMain{
	display: flex;
	flex-direction: row;
	height: 100%;
}

.NewFileModalContent {
    display: flex;
	background: rgba(23, 25, 30, 0.9);
    backdrop-filter: blur(10px);
	border-radius: 10px;
	box-shadow:
		hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
    height: 100%;
	max-width: 730px;
	max-height: 480px;
	padding: 30px;
	animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    flex-direction: column;
	row-gap: 50px;
}

.NewFileModalButtonContainer{
	display: flex;
	justify-content: center;
}

.CreateFileButtonSelected{
	font-family: "Inter", sans-serif;
	background-color: #35395c;
	height: 40px;
	border: none;
	width: 260px;
	border-radius: 10px;
	color: rgba(255, 255, 255, 1);
	font-size: 16px;
}

.CreateFileButton{
	font-family: "Inter", sans-serif;
	background-color: #30303D;
	height: 40px;
	border: none;
	width: 260px;
	border-radius: 10px;
	color: rgba(255, 255, 255, 0.5);
	font-size: 16px;
}

.NewFileModalTitle{
	display: flex;
	flex-direction: column;
	max-width: 300px;
}

.NewFileModalSidePanel {
    flex: 0.3;
	border-right-color: rgba(255, 255, 255, 0.1);
	border-right-width: 1px;
	border-right-style: solid;
	padding-right: 30px;
}

.NewFileModalMainContent{
    flex: 1;
	padding-left: 30px;
}

.NewFileGrid{
	/* background-color: rgba(25, 23, 30, 0.9); */
	padding: 10px;
	margin-top: 30px;
	border-radius: 10px;
	overflow: hidden;
}

.NewFileModalContent:focus {
	outline: none;
}

.NewFileModalTitle {
	margin: 0;
	font-weight: 500;
	color: #000;
	font-size: 17px;
}

.NewFileModalTitle label {
	font-weight: 600;
	color: #fff;
	font-size: 16px;
	margin-bottom: 10px;
}

.NewFileModalTitle input {
	font-family: "Inter", sans-serif;
	height: 30px;
	background-color: #29292E;
	border: none;
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	padding: 0px 10px;
}

.NewFileModalTitle input:focus {
	border: none;
	outline: none;
}

.NewFileModalFilters{
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.NewFileModalFilterButtonSelected{
	font-family: "Inter", sans-serif;
	height: 40px;
	background-color: #292B42;
	border: none;
	border-radius: 5px;
	color: #fff;
}

.NewFileModalFilterButton{
	font-family: "Inter", sans-serif;
	height: 40px;
	background-color: rgba(48, 48, 61, 0.2);
	border: none;
	border-radius: 5px;
	color: #fff;
}

.NewFileModalFilterButton:hover{
	background-color: #25252E;
}


.NewFileModalButton {
	font-family: "Inter", sans-serif;
	font-size: 26px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	padding: 0 15px;
	line-height: 1;
	font-weight: 300;
	height: 100%;
	background-color: #292B42;
	border: none;
	color: #fff;
	gap: 10px;
	height: 235px;
}

.NewFileModalButton:hover{
	background-color: #25252E;
}

.ScreenItem{
	display: flex;
	background-color: #292B42;
	justify-content: center;
	align-items: center;
	margin: 10px;
	border-radius: 5px;
	height: 120px;
	padding: 0px 20px;
	text-align: center;
}

.ScreenItem:hover{
	background-color: #25252E;
}

.ScreenItem p {
	font-family: "Inter", sans-serif;
	font-size: 14px;
	color: #fff;
}

.ScreenItemSelected{
	display: flex;
	background-color: #30303D;
	justify-content: center;
	align-items: center;
	margin: 10px;
	border-radius: 5px;
	height: 120px;
	padding: 0px 20px;
	text-align: center;
}

.ScreenItemSelected p {
	font-family: "Inter", sans-serif;
	font-size: 14px;
	color: #fff;
}
