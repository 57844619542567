.element-panel-container{
    display: inline-block;
    background: rgba(25 23, 30, 0.9);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    z-index: 100;
    color: white;
    margin-bottom: 10px;
    padding: 20px;
    width: 260px;
    box-sizing: border-box;
}

.element-panel-section{
    border-bottom: #4B4C62 solid 1px;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.element-panel-title{
    border-bottom: #4B4C62 solid 1px;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.element-panel-title p{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}

.element-panel-input input, .element-panel-input textarea {
    background-color: #29292E;
    border: none;
    outline: none;
    height: 24px;
    width: 100%;
    border-radius: 5px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #fff;
    width: 100%;
    margin-bottom: 15px;
    padding: 0px 5px;
}

.element-panel-input input[type=number]::-webkit-inner-spin-button,
.element-panel-input input[type=number]::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
}

.element-panel-input p{
    font-family: "Inter", sans-serif;
    font-size: 13px;
    color: #fff;
    margin-bottom: 2px;
}

.element-panel-input textarea, .element-panel-input-textfield-value{
    height: 80px;
    resize: none;
    margin-bottom: 15px;
}

.element-panel-dropdown-trigger{
    margin-bottom: 15px;
}

.element-panel-dropdown-trigger p {
    font-family: "Inter", sans-serif;
    font-size: 13px;
    color: #fff;
    margin-bottom: 2px;
}

.element-panel-dropdown-trigger button{
    display: flex;
    background-color: #29292E;
    width: 100%;
    height: 28px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #fff;
    border-radius: 5px;
    border: none;
    outline: none;
    justify-content: space-between;
    align-items: center;
}

.element-panel-dropdown-content{
    width: 220px;
    background-color: #29292E;
    backdrop-filter: blur(40px);
    margin-top: 5px;
    color: #fff;
    border-radius: 10px;
    height: auto;
    max-height: 150px;
    overflow: hidden;
    overflow-y: auto;
}

.element-panel-dropdown-content div{
    color: #fff;
    font-size: 14px;
}

.element-panel-dropdown-content div:hover{
    background-color: #292B42;
}

.element-panel-colour{
    margin-bottom: 15px;
}

.element-panel-colour > div:first-of-type{
    display: flex;
    flex-direction: row;
    background-color: #29292E;
    border-radius: 5px;
    gap: 10px;
    padding: 5px;
    align-content: center;
    align-items: center;
}

.element-panel-colour p{
    font-family: "Inter", sans-serif;
    font-size: 13px;
    color: #fff;
    margin-bottom: 2px;
}

.element-panel-colour > div:first-of-type div{
    width: 20px;
    height: 20px;
    border-radius: 2px;
}

.element-panel-colour > div:first-of-type p{
    color: #fff;
}

/* Custom layout */
.element-panel-colour-picker{
    height: auto;
    padding: 10px;
    border-radius: 10px;
    background-color: #29292E;
    backdrop-filter: blur(40px);
}

.react-colorful{
    background-color: transparent;
    margin: 0px;
}

.element-panel-colour-picker input{
    border: none;
    outline: none;
    width: 100%;
    background-color: #29292E;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}

.element-panel-colour-picker .react-colorful__saturation {
    margin: 15px 0;
    border-radius: 5px;
    border-bottom: none;
}

.element-panel-colour-picker .react-colorful__hue {
    order: -1;
}

.element-panel-colour-picker .react-colorful__hue,
.element-panel-colour-picker .react-colorful__alpha {
    height: 14px;
    border-radius: 5px;
}

.element-panel-colour-picker .react-colorful__hue-pointer,
.element-panel-colour-picker .react-colorful__alpha-pointer {
    width: 20px;
    height: 20px;
}

.element-panel-border-radius p:first-of-type{
    font-family: "Inter", sans-serif;
    font-size: 13px;
    color: #fff;
    margin-bottom: 2px;
}

.element-panel-border-radius-grid{
    display: grid;
    grid-template-columns: 80px 80px;
    gap: 10px 20px;
}

.element-panel-border-radius-grid div{
    display: flex;
    flex-direction: row;
    width: 80px;
    height: 30px;
    background-color: #29292E;
    align-items: center;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.element-panel-border-radius-grid div input{
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
    width: 100%;
    margin-left: 10px;
}

.element-panel-tabs div{
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    gap: 5px;
}

.element-panel-tabs div button{
    flex-grow: 1;
    background-color: rgba(24, 25, 44, 0.2);
    border: none;
    outline: none;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    align-content: center;
    color: #777FBD;
    height: 24px;
}

.element-panel-tabs div button:hover{
    flex-grow: 1;
    background-color: rgba(24, 25, 44, 0.4);
}

.element-panel-tabs p{
    font-family: "Inter", sans-serif;
    font-size: 13px;
    color: #fff;
    margin-bottom: 2px;
}

.element-panel-tabs-selected{
    background-color: #292B42 !important;
    color: #fff !important;
}

.element-panel-upload p{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
}

.element-panel-upload button{
    display: flex;
    background-color: rgba(24, 25, 44, 0.3);
    width: 100%;
    height: 28px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #fff;
    border-radius: 5px;
    border: none;
    outline: none;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

.element-panel-upload button:hover{
    background-color: rgba(24, 25, 44, 0.5)
}

.element-panel-code-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    color: #fff;
    margin-bottom: 10px;
}

.element-panel-code-header button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #292B42;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 5px;
    padding: 5px;
}

.element-panel-code-header button:hover{
    background-color: #30303D;
}