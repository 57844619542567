p,h1, h2, h3, h4, h5, h6{
    padding: 0px;
    margin: 0px;
}

:root {
    --tb-header-width: 310px;
    --tb-share-width: 180px
}

.tb-container{
    display: flex;
    position: fixed;
    width: 100%; 
    height: auto;
    z-index: 1000;
    margin: 0;
    color: white;
    /* padding: 20px 20px 0px 20px; */
    justify-content: space-between;
}


.tb-header{
    font-family: "Inter", sans-serif;
    display: flex;
    flex-direction: row;
    background: rgba(25, 23, 30, 0.9);
    backdrop-filter: blur(40px);
    margin-top: 10px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 35px;
    width: var(--tb-header-width);
    border-radius: 5px;
    align-items: center;
    gap: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tb-header span{
    font-size: 12px;
    color: #D138E5;
    font-weight: 800;
}

.tb-header div p, .tb-header div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tb-logo{
    color: #fff;
    font-family: "Inter", sans-serif;
    font-family: 36px;
    background-color: transparent;
    border: none;
}

.tb-elements{
    margin-right: 150px;
}

.tb-share{
    display: flex;
    font-family: "Inter", sans-serif;
    flex-direction: row;
    justify-content: flex-end;
    background: rgba(25, 23, 30, 0.9);
    backdrop-filter: blur(40px);
    margin-top: 10px;
    margin-right: 10px;
    height: 35px;
    width: var(--tb-share-width);
    border-radius: 5px;
    align-items: center;
    gap: 10px;
    padding: 5px;
    box-sizing: border-box;
}


.tb-share-button{
    display: flex;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    text-align: center;
    width: 80px;
    height: 100%;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    color: #9B90F2;
    background-color: #18192C; 
}

.tb-share-button:hover{
    background-color: #232330;
}

.tb-play-button{
    width: 80px;
    height: 100%;
    border-radius: 5px;
}

.tb-play-button button{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    text-align: center;
    width: 80px;
    height: 100%;
    border-radius: 5px;
    color: #38E577;
    align-items: center;
    background-color: rgba(56, 229, 119, 0.2);
    gap: 5px;
}

.tb-play-button:hover{
    background-color: #15231A;
}

.tb-share-dropdown-title{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    border-bottom: #4B4C62 1px solid;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.tb-share-dropdown-access p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #777FBD;
    margin-bottom: 10px;
    border-bottom: #4B4C62 1px solid;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.tb-share-dropdown-link p:nth-child(1){
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin-bottom: 10px;
    color: #777FBD;
}

.tb-share-dropdown-link button{
    display: flex;
    background-color: #292B42;
    border: none;
    outline: none;
    color: #fff;
    align-content: center;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 10px 50px;
    border-radius: 5px;
    gap: 5px;
}

.tb-share-dropdown-link button:hover{
    background-color: #30303D;
}
