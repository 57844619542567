.set-screen-home{
    display: flex;
    font-family: "Inter", sans-serif;
    color: #9B90F2;
    font-size: 14px;
    background-color: #292B42;
    width: 100%;
    height: 30px;
    border: none;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px;
    margin-bottom: 15px;
}