.passsDataContainer{
    display: flex;
    flex-direction: row; 
    align-items: center; 
    justify-content: space-between;
    margin-top: 30px;
}

.prototypeMenuItemTitle{
    font-weight: 400;
}