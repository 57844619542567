.checkout-payment-succesful{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #38E577;
    gap: 10px;
    margin-bottom: 50px;
}

.checkout-payment-succesful svg{
    width: 50px;
    height: 50px;
    color: #38E577;
}