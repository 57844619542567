.SliderRoot {
	position: relative;
	display: flex;
	align-items: center;
	user-select: none;
	touch-action: none;
	width: 100%;
	height: 20px;
}

.SliderTrack {
	background-color: #fff;
	position: relative;
	flex-grow: 1;
	border-radius: 9999px;
	height: 12px;
}

.SliderRange {
	position: absolute;
	background-color: #616599;
	border-radius: 100px 0px 0px 100px;
	height: 100%;
}

.SliderThumb {
	display: block;
	width: 20px;
	height: 20px;
	background-color: #30303D;
	border-radius: 10px;
    border: none;
    outline: none;
}

.your-team-range-slider{
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: rgba(255, 255, 255, 0.04);
    padding: 10px;
    padding-bottom: 55px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.your-team-numusers-min{
    width: 50px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
}

.your-team-numusers-max{
    width: 80px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
}

.SliderThumbInfo{
  display: flex;
  position: relative;
  width: 80px;
  height: 40px;
  background-color: #30303D;
  border-radius: 10px;
  color: #fff;
  justify-content: center;
  align-items: center;
  top: 25px;
  right: 30px;
}

.yourteam-box-button{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    background-color: #292B42;
    border: none;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}

.yourteam-box-button:hover{
    background-color: #616599;
}