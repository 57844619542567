
.screen-selector-container{
    display: flex;
    flex-direction: column;
}

.screen-selector-title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.screen-selector-title{
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: #fff;
}

.screen-selector-add-screen{
    display: flex;
    flex-direction: row;
}

.screen-selector-add-screen button{
    display: flex;
    font-family: "Inter", sans-serif;
    color: #9B90F2;
    font-size: 14px;
    background-color: #292B42;
    width: 30px;
    height: 25px;
    border: none;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px;
}

.screen-selector-add-screen button:hover{
    background-color: #30303D;
}

.screen-selector-screens-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    max-height: 150px;
    overflow-y: auto;
}

.screen-selector-screen{
    display: flex;
    font-size: 12px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: #fff;
    border-radius: 5px;
    padding: 5px;
}

.screen-selector-screen:hover{
    background-color: #30303D;
}

.screen-selector-screen[data-highlighted="true"]{
    background-color: #292B42;
}