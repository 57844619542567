@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.selectplan-main{
    background-color: #212226;
    height: 100vh;
    font-family: "Inter", sans-serif;
}

.selectplan-header-container{
    padding: 20px;
}

.selectplan-header{
    color: #fff;
    font-family: "Inter", sans-serif;
}

.selectplan-header span{
    font-size: 14px;
    color: #D138E5;
    font-weight: 800;
}

.selectplan-box{
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #212226;
    background: 
    linear-gradient(#212226 0 0) padding-box, /*this is your grey background*/
    linear-gradient(to bottom, #282A3F 0%, #414356 100%) border-box,
    linear-gradient(to bottom, #414356 0%, #282A3F 100%) border-box;
    color: #313149;
    width: auto;
    padding: 40px;
    border: 1px solid transparent;
    border-radius: 15px;
    -webkit-box-shadow: 4px 0px 60px 16px rgba(38,39,55,0.5);
    -moz-box-shadow: 4px 0px 60px 16px rgba(38,39,55,0.5);
    box-shadow: 4px 0px 60px 16px rgba(38,39,55,0.5);
    top:  50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.selectplan-box-header{
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
}

.selectplan-box-input{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.selectplan-box-input label{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.selectplan-box-input input{
    height: 35px;
    border-radius: 10px;
    box-sizing: unset;
    border: none;
    padding: 0px 10px;
}

.selectplan-box-button{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    background-color: rgba(81, 90, 177, 0.6);
    border: none;
    margin-bottom: 20px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}

.selectplan-options{
    display: flex;
    flex-direction: row;
}

.selectplan-option-container{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: auto;
    background-color: rgba(255, 255, 255, 0.05);
    margin: 0px 10px;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
}

.selectplan-option-container:hover{
    background-color: #30303D;
}

.selectplan-pro{
    padding: 30px 30px 30px 30px;
}

.selectplan-option-container h1{
    color: #fff;
    font-size: 26px;
    width: 100%;
    padding: 0px;
    margin-bottom: 20px;
}

.selectplan-option-pricing{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.selectplan-discount{
    position: relative;
    display: flex;
    width: 160px;
    height: 30px;
    background-color: #D138E5;
    border-radius: 100px;
    font-size: 14px;
    color: #fff;
    justify-content: center;
    bottom:  10px;
    text-align: center;
    align-items: center;
}

.selectplan-option-pricing h2{
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.selectplan-option-features{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.selectplan-option-features h4{
    color: #fff;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 18px;
}

.selectplan-option-features .selectplan-coming-soon{
    margin-top: 10px;
}

.selectplan-features{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    gap: 5px;
    font-size: 16px;
    margin-bottom: 5px;
}

.coming-soon{
    font-size: 12px;
    margin-bottom: 5pxs;
}

.selectplan-cta{
    position: relative;
    width: 100%;
    height: 40px;
    background-color: #43476c;
    border-radius: 10px;
    outline: none;
    border: none;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    bottom: 0;
    text-align: center; 
}

.selectplan-cta:hover{
    background-color: #515AB1;
}

.selectplan-enterprise-description{
    font-size: 14px;
    color: #fff;
    text-align: left;
}