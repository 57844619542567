.add-ds-container{
    height: auto;
    padding: 16px;
    width: 218px;
    border-radius: 10px;
    background-color: rgba(55, 57, 75, 0.8);
    backdrop-filter: blur(40px);
}

.add-ds-trigger{
    background-color: #3E447D;
    color: #fff;
    padding: 6px 10px;
    border-radius: 5px;
    margin-right: 10px;
}

.add-ds-title{
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;
}

.add-ds-title p{
    color: #fff;
    font-weight: 500;
}

.add-ds-input{
    color: #fff;
    margin-bottom: 10px;
}
.add-ds-input p{
    margin-bottom: 5px;
    font-size: 14px;
}

.add-ds-container input{
    height: 30px;
    border: none;
    outline: none;
    width: 100%;
    background-color: rgba(24, 25, 44, 0.3);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}

.add-ds-button{
    width: 100%;
    height: 30px;
    background-color: #3E447D;
    border: none;
    border-radius: 5px;
    color: #fff;
    margin-top: 15px;
}

.add-ds-button-disabled{
    width: 100%;
    height: 30px;
    background-color: #5A5A5A;
    border: none;
    border-radius: 5px;
    color: #fff;
    margin-top: 15px;
}

.add-ds-button:hover{
    background-color: #353F9D;
}